.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content > img {
  display: block;
  width: 100%;
  max-width: 12rem;
}

.modal {
  max-width: 25rem;
}

.content > p {
  margin: 0.5rem 0;
  color: #777E90;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.5px;
}

.twitter_share_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1rem;
}

.twitter_share_button > * {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.twitter_share_button > *:last-child {
  margin-top: 1rem;
}

.infographic_button {
  border: 2px solid #5E6AFC;
}

.infographic_button:hover {
  color: #5E6AFC!important;
  background: transparent;
  border: 2px solid #5E6AFC;
}

@media (max-height: 900px) {
  .scroll {
    height: 100%;
    max-height: 65vh;
    overflow-y : scroll;
  }
}
