.app_view_wrapper {
  padding-bottom: 32px;
}

.hero_main_wrapper {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url('/src/assets/bg.gif') center center no-repeat;
  background-size: cover;
}

.header {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 1120px;
}

.header_img {
  height: 35px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.main_h3 {
  padding: 0;
  margin: 0;
  color: #FFF;
  font-family: 'Aeonik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.main_info {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0;
  margin: 0;
}

.main_info_a {
  color: #FFF;
  font-family: 'Aeonik';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.main_info_button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
}

.main_info_link {

}

.main_info_button_img {
  width: 44px;
  height: 44px;
}

.main_copy_text {
  padding: 2px 6px;
  margin: 0;
  border-radius: 4px;
  background: #41ABE1;
  color: #FBFEFF;
  font-family: 'Aeonik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  cursor: default;
  transition: opacity 0.3s ease-in-out;
}

.main_copy_text.copied {
  opacity: 1;
}

@media (max-width: 575px) {
  .main_info_span {
    font-size: 16px;
  }

  .main_info_button {
    width: 20px;
    height: 20px;
  }

  .main_info_button_img {
    width: 20px;
    height: 20px;
  }
}
