.earn_page {
  padding: 0 2rem;
}

.margin_top {
  margin-top: 3rem;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 6rem;
  max-width: 1400px;
  margin: 0 auto;
}

.row > img {
  width: 40%;
}

.staking_block {
  display: flex;
  flex-direction: column;
  width: 27rem;
  max-width: calc(100vw - 4rem);
  margin-left: 2rem;
}

.staking_block > h2 {
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.5rem;
}

.staking_block > div:not(.staking) {
  display: flex;
  justify-content: space-between;
}

.staking_block > div:not(.staking) > p {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.5rem;
  color: var(--main-black);
}

.staking_block > div:not(.staking) > p:first-child {
  color: var(--text-blue);
}

.staking_block > p {
  margin: 0.5rem 0 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
  color: var(--text-blue);
}

.staking_block > p > span {
  font-weight: bold;
  color: var(--main-blue);
}

.staking_block > button {
  margin-top: 1rem;
  align-self: flex-end;
  border: 2px solid var(--main-blue);
  text-transform: unset;
}

.staking {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.staking > button {
  margin-top: 1rem;
  align-self: flex-end;
  border: 2px solid var(--main-blue);
  text-transform: unset;
}

.staking > label {
  position: relative;
  width: 100%;
}

.staking > label > input {
  width: 100%;
  padding: 1rem 5rem 1rem 1rem;

  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;

  letter-spacing: 0.5px;

  background: #FBFEFF;

  border: 1px solid #EDE6E6;
  box-sizing: border-box;

  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.05), 0px 2px 15px -3px rgba(15, 23, 42, 0.05), 0px 2px 6px -2px rgba(15, 23, 42, 0.025);
  border-radius: 24px;
}

.staking > label > button {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
  background: none;
  border: none;
  color: var(--main-blue);
  font-weight: bold;
  cursor: pointer;
}

.staking > label > button:hover {
  border: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.staking_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.staking_row > p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  color: var(--text-blue);
}

.staking_row > div > p {
  color: var(--main-black);
  font-weight: bold;
}

.staking > div:first-child {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.staking > div:first-child > button {
  width: 100%;
  padding-bottom: 1rem;
  background: none;
  border-bottom: 4px solid transparent;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.5px;

  cursor: pointer;
}

.staking > div:first-child > button:disabled {
  color: var(--main-black);
}

.staking > div:first-child > button:not(:disabled):hover {
  border: none;
  border-bottom: 4px solid transparent;
}

:not(.active_stake_btn) {
  border: none;
}

.staking > div:first-child > button.active_stake_btn {
  border: none;
  border-bottom: 4px solid var(--main-blue);
  font-weight: bold;
}

.text_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 27rem;
}

.text_block h2 {
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.5rem;
}

.text_block p {
  color: var(--text-blue);
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  margin: 0 0 1.5rem;
}

.text_block p.no_mb {
  margin: 0;
}

.text_block .accent {
  color: var(--main-blue);
  font-weight: bold;
}

.text_block ul, .text_block ul li {
  margin: 0;
  padding: 0;
}

.text_block ul {
  color: var(--text-blue);
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  list-style-position: inside;
}

.text_block ul li {
  margin-top: 0.75rem;
}

.text_block .comming_soon {
  width: 100%;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--main-black);
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: bolder;
  color: var(--main-blue);
}

.comming_soon > a {
  color: #5E6AFC;
}

.comming_soon > a:hover {
  border: none;
}

@media screen and (min-width: 28rem) {
  .staking_block button {
    font-size: 1.25rem!important;
  }

  .staking_block input {
    font-size: 1.25rem!important;
  }

  .staking_block p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 48rem) {
  .row {
    justify-content: center;
    padding-top: 2rem;
  }

  .row img {
    display: none;
  }

  .staking_block {
    margin-left: unset;
  }

  .staking > button, .staking_block > button {
    width: 100%;
  }
}

@media (max-width: 28rem) {
  .text_block h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .text_block p {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .text_block ul {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .text_block .comming_soon {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .staking_block {
    max-width: unset;
  }
}
