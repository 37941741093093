.wallet_modal {
  max-width: 25rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container > button {
  border: 2px solid #5E6AFC;
}

.container > button:last-child img {
  filter: brightness(0) invert();
}

.container > button:last-child:hover img {
  filter: none;
}

.container > button:hover {
  color: #5E6AFC!important;
  background: transparent;
  border: 2px solid #5E6AFC;
}

.container > * {
  display: flex;
  align-items: center;
  width: 100%;
}

.container > * > img {
  display: block;
  margin-right: 0.75rem;
}

.container > *:first-child {
  margin-bottom: 1.5rem;
}
