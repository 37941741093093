.styled_modal {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 544px;
  margin: 30px;
  background: var(--background-color);
  border-radius: 20px;
  outline: none;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  background: #FBFEFF;
  border-radius: 2rem;
}

.modal_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.modal_title {
  color: var(--text-blue);
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  line-height: 1.25em;

  letter-spacing: -0.01em;
}

.modal_close_button {
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  color: #5E6AFC;
  font-size: 14px;
  line-height: 1em;
  border: 2px solid #5E6AFC;
  background: none;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.modal_close_button:hover,
.modal_close_button:focus {
  color: #E5E5E5;
  background: #5E6AFC;
}
