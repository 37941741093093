@font-face {
  font-family: "Aeonik";
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 600;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 800;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 900;
  font-style: normal;
  font-display: auto;
  src: url("./assets/fonts/Aeonik-Bold.ttf") format("opentype");
}

#root {
  --background-color: #E5E5E5;
  --main-blue: #5E6AFC;
  --main-black: #23262F;
  --white: #FBFEFF;
  --text-blue: #777E90;
  --card-background: #FAFCFF;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Aeonik, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #fff;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Body--open {
  overflow: hidden;
}
