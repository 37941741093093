.modal {
  max-width: 25rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.content > img {
  display: block;
  width: 100%;
  max-width: 12rem;
}

.address_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
}

.address_block > button {
  background: none;
  border: none;
  cursor: pointer;
}

.address_block > div {
  text-align: left;
  width: 100%;
}

.address_block textarea {
  resize: none;
  background: none;
  border: none;
  overflow: hidden;
  width: 100%;

  color: #5E6AFC;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.5px;
}

.address_block p {
  margin: 0;
  padding-top: 0.5rem;
  color: #5E6AFC;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.5px;
}
