.button {
  padding: 1rem 2rem;
  color: #FBFEFF;
  background: #5E6AFC;
  border: 2px solid var(--white);
  border-radius: 5.625rem;

  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.125em;
  text-transform: uppercase;

  text-align: center;
  text-decoration: none;
  letter-spacing: 0.5px;

  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.button:disabled {
  background: rgba(94, 106, 252, 0.5);
  cursor: default;
}

.button:not(:disabled):hover {
  background: transparent;
  color: var(--white);
}

.disabled_link {
  opacity: 0.5;
  cursor: default;
}
