.modal {
  width: 25rem;
}

.img {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content > p {
  margin: 1rem 0;
  color: #777E90FF;
  width: 100%;
}
